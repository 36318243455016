
import { defineComponent, reactive, onDeactivated, onMounted } from 'vue'
import $bus from '@/utils/bus'
import { isElemVisible } from '@/utils/util'
import { onBannerLoadAndActivated } from './util'
export default defineComponent({
    setup() {
        const cases = reactive([
            { brand: '爱渡', shop: '全国接入34家', logo: 'ad' },
            { brand: '佳偶意诚', shop: '全国接入5家', logo: 'joyc' },
            { brand: '完美先生', shop: '全国接入3家', logo: 'wmxs' },
            { brand: '京恋', shop: '全国接入3家', logo: 'jl' },
            { brand: '遇喜', shop: '全国接入3家', logo: 'yuxi' },
            { brand: '心意网', shop: '全国接入2家', logo: 'xyw' },
            { brand: '爱遇空间', shop: '全国接入2家', logo: 'aykj' },
            { brand: '缘禧', shop: '全国接入2家', logo: 'yuanxi' },
            { brand: '爱慕婚恋', shop: ' ', logo: 'amhl' },
            { brand: '铂爱', shop: ' ', logo: 'ba' },
            { brand: '钟爱网', shop: ' ', logo: 'zaw' },
            { brand: '优缘', shop: ' ', logo: 'yy' },
            { brand: '缘定三生', shop: ' ', logo: 'ydss' },
            { brand: '一对', shop: ' ', logo: 'yd' },
            { brand: '晓阳', shop: ' ', logo: 'xy' },
            { brand: '心恋', shop: ' ', logo: 'xl' },
            { brand: '禧合', shop: ' ', logo: 'xh' },
            { brand: '心动的信号', shop: ' ', logo: 'xddxx' },
            { brand: '盛和缘', shop: ' ', logo: 'shy' },
            { brand: '上海红娘网', shop: ' ', logo: 'shhnw' },
            { brand: '全城热恋', shop: ' ', logo: 'qcrl' },
            { brand: '怦然心动', shop: ' ', logo: 'prxd' },
            { brand: 'ONLYYOU', shop: ' ', logo: 'oy' },
            { brand: '恋爱树', shop: ' ', logo: 'las' },
            { brand: '锦缘网', shop: ' ', logo: 'jyw' },
            { brand: '佳偶天成', shop: ' ', logo: 'jotc' },
            { brand: '红缘婚恋', shop: ' ', logo: 'hyhl' },
            { brand: '唯尔', shop: ' ', logo: 'we' },
            { brand: '全国已有300+门店使用爱乐云...', shop: '', logo: 'moreShop' },
        ])
        function consult() {
            $bus.emit('CONSULT_POPUP', true)
        }
        let casesElements: Array<HTMLElement>;
        let waitFadeIncases: Array<HTMLElement> = [];
        function handleScroll() {
            casesElements = casesElements.filter(el => {
                if (isElemVisible(el, -130)) {
                    waitFadeIncases.push(el)
                    setTimeout(() => {
                        el.classList.add('case-fade-in')
                        waitFadeIncases.shift()
                    }, 80 * waitFadeIncases.length)
                    return false
                } else {
                    return true
                }
            })
            if (!casesElements.length) {
                document.removeEventListener('scroll', handleScroll)
            }
        }
        onMounted(() => {
            casesElements = Array.from(document.getElementsByClassName('case-item')) as Array<HTMLElement>
        })
        onBannerLoadAndActivated(() => {
            if (casesElements.length) {
                document.addEventListener('scroll', handleScroll)
                handleScroll()
            }
        })
        onDeactivated(() => {
            document.removeEventListener('scroll', handleScroll)
        })
        return { cases, consult }
    }
})
