import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, KeepAlive as _KeepAlive, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56db0006"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { style: {"overflow":"hidden"} }
const _hoisted_3 = { class: "features fade-in first-screen" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "features-name" }
const _hoisted_6 = { class: "features-desc" }
const _hoisted_7 = { id: "pageContent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_Affix = _resolveComponent("Affix")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createElementVNode("img", {
      class: "banner fade-in first-screen",
      src: require('@/assets/images/banner.jpg')
    }, null, 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.features, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "features-item fade-in first-screen",
            key: item.value
          }, [
            _createElementVNode("img", {
              class: "features-img",
              src: require(`@/assets/images/features${item.value + 1}.png`)
            }, null, 8, _hoisted_4),
            _createElementVNode("div", _hoisted_5, _toDisplayString(item.name), 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString(item.desc), 1)
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          (_openBlock(), _createBlock(_KeepAlive, null, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ], 1024))
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_Footer),
    _createVNode(_component_Affix)
  ], 64))
}