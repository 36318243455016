
import { defineComponent, reactive, onMounted } from 'vue'
import Header from './components/header.vue';
import Footer from './components/footer.vue'
import Affix from './components/affix.vue';
export default defineComponent({
    components: {
        Header,
        Footer,
        Affix,
    },
    setup() {
        const features = reactive([
            { name: '资源好 数量足', value: 0, desc: '按需投放，全网优质流量\n，有力支撑业务快速增长' },
            { name: '收费合理', value: 1, desc: '按使用量付费，有足够的\n利润空间，彻底告别加盟\n等被盘剥模式' },
            { name: '功能强大', value: 2, desc: '汲取珍爱、佳缘百合的系统\n优点， 提供覆盖邀约、销售\n、服务、财务、行政、市场\n全岗位的系统支撑' },
            { name: '获客稳定 不被卡脖子', value: 3, desc: '渠道广，持续稳定的资源，\n再也不怕被卡脖子' },
            { name: '持续更新', value: 4, desc: '适应快速发展的行业需求，\n以周为单位快速迭代升级，\n系统免费升级' },
            { name: '安全性', value: 5, desc: '公安部备案，通过国家信\n息系统安全等级保护三级\n定级。充分保障信息安全\n' },
        ])

        onMounted(() => {
            const list = Array.from(document.getElementsByClassName('first-screen')) as Array<HTMLElement>
            list.forEach(el => el.classList.add('fade-in-end'))
        })
        return {
            features
        }
    }
})
