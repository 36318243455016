
import { defineComponent, ref, reactive, } from "vue";
import SwiperClass, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import "swiper/swiper-bundle.min.css";
import $bus from "@/utils/bus";
import { isElemVisible } from "@/utils/util";
import { onBannerLoadAndActivated } from "./util";
export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    // 核心能力介绍
    interface ItabItem {
      swiper?: SwiperClass;
      [propName: string]: any;
    }
    const introduceTabs: Array<ItabItem> = reactive([
      { name: "运营系统", value: 0, url: "get" },
      { name: "运营支持系统", value: 1, url: "operation" },
    ]);

    const servicesList = [
      {
        title: "运营系统",
        serviceContent: [
          {
            title: "数据中心",
            icon: "icon-1", // 替换为实际图标路径
            description:
              "实时了解业务表现，精准优化运营策略，提升客户转化，提高中台使用效率，优化资源配置",
          },
          {
            title: "呼叫中心",
            icon: "icon-2",
            description:
              "打通筛客育客触客全流程。智能工具加速客户转化。接通率远超同行30%",
          },
          {
            title: "客户管理系统",
            icon: "icon-3",
            description:
              "数据库汇聚各渠道商机，按标签追踪来源等。客户备有专属信息，构建客户画像。",
          },
          {
            title: "公海资源激活",
            icon: "icon-4",
            description:
              "新增公海AI智能推荐功能，盘活潜在客户资源，公海业绩环比提升126.3%",
          },
          {
            title: "服务系统",
            icon: "icon-5",
            description: "针对付费会员个性化差异提供全周期的定制化服务方案。",
          },
          {
            title: "订单系统",
            icon: "icon-6",
            description: "订单聚合统一管理，处理高效透明，便于管理盘点。",
          },
        ],
      },
    ];

    const customersList = [
      {
        title: "投放获客",
        points: [
          "精准投放，客户质量好",
          "全渠道获客，资源几乎没有叠加",
          "客户对品牌有良好认知，易转化",
          "自己的品牌，完全自主掌控",
        ],
      },
      {
        title: "新媒体运营获客",
        points: [
          "专业运营获客，多平台多形式",
          "资源层层筛选，高意向高转化",
          "运营团队协作默契，实时信息同步",
          "安全性保障，零风险",
        ],
      },
      {
        title: "IP视频运营获客",
        points: [
          "打造门店自主IP账号，拉升转化，有质迅增量",
          "短视频+直播+人设IP，覆盖范围广，精准定向",
          "无需雇人，轻成本投入，可追踪性强",
        ],
      },
      {
        title: "地推获客",
        points: [
          "直接接触目标客户，快速了解需求，提升转化率",
          "收策客户详细清楚，便于后续精准营销和用户分析",
          "促进口碑传播，增强品牌认知度",
        ],
      },
    ];
    const activateIntroduce = ref(0);

    function showPopup() {
      $bus.emit("CONSULT_POPUP", true);
    }


    function goAnchor(id: string) {
      window.scrollTo({
        top: (id == "operation" ? 1500 : 800),
        behavior: "smooth", // 平滑滚动
      });
    }
    $bus.on("GOANCHOR", goAnchor);

    return {
      introduceTabs,
      activateIntroduce,
      swiperModules: [Navigation, Pagination, Autoplay],
      showPopup,
      servicesList,
      customersList,
    };
  },
});
